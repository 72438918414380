
const digest = 'e200b22b150454002b6c210e15355226158c1f949d8776f2528bb4a3b4368d27';
const css = `._button_o4fw5_1 {
  --button-color: tranparent;
  --text-color: var(--color-neutral-10);
  --border-color: transparent;
  background: var(--button-color);
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 1.5rem;
  transition: all 0.25s var(--transition);
}

._button_o4fw5_1[aria-disabled="true"] {
  opacity: 0.2;
}

/* Sizes */

._medium_o4fw5_23 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.75rem 1.5rem;
}

._small-medium_o4fw5_30 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  padding: 0.6875rem 1rem;
}

._small_o4fw5_30 {
  font-size: 1rem;
  height: 2rem;
  min-width: 2rem;
  padding: 0 1.5rem;
}

._extra-small_o4fw5_44 {
  font-size: 0.75rem;
  height: 1.5rem;
  min-width: 2rem;
  padding: 0 1.5rem;
}

/* Colors */
._cancel_o4fw5_52,
button._button--cancel_o4fw5_53 {
  --border-color: var(--color--neutral-10);
  --button-color: tranparent;
  --text-color: var(--color-neutral-10);
}

._action_o4fw5_59,
button._button--action_o4fw5_60 {
  --border-color: var(--color-brand);
  --button-color: var(--color-brand);
  --text-color: var(--color-white);
}

._delete_o4fw5_66,
button._button--delete_o4fw5_67 {
  --border-color: var(--color-red);
  --button-color: var(--color-red);
  --text-color: var(--color-white);
}

._outline_o4fw5_73 {
  --border-color: var(--color--neutral-10);
  --button-color: tranparent;
  --text-color: var(--color-neutral-10);
}

._gray_o4fw5_79 {
  --border-color: transparent;
  --button-color: var(--color-legacy-gray-1);
  --text-color: var(--color-neutral-40);
}

._outline_o4fw5_73:hover,
._outline_o4fw5_73:active,
._outline_o4fw5_73:focus {
  --border-color: var(--color-neutral-10);
  --button-color: tranparent;
  --text-color: var(--color-neutral-40);
}

._button--outline-icon_o4fw5_93 {
  display: flex;
  align-items: center;
  background: var(--color-white);
  border-color: var(--color--neutral-10);
  color: var(--color-neutral-10);
  transition: all 0.25s var(--transition);
}

._button--outline-icon_o4fw5_93:hover {
  border-color: var(--color-neutral-40);
  color: var(--color-neutral-40);
}

/* Variants */

._rounded_o4fw5_109 {
  --border-radius: 10rem;
}

._borderless_o4fw5_113 {
  --border-color: tranparent;
  --button-color: tranparent;
  padding: 0;
  font-weight: 600;
}

._actionButtons_o4fw5_120 {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

._actionButtons_o4fw5_120 ._button_o4fw5_1 {
  margin-left: 0.5rem;
}

._buttonExport_o4fw5_130 {
  margin-right: 0.5rem;
}

/* Text colors */
._red_o4fw5_135 {
  --text-color: var(--color-red);
}

/* To be refactored into button system */

._button--table_o4fw5_141 {
  background-color: transparent;
  border: 1px solid var(--color--neutral-10);
  border-radius: 0.25rem;
  --text-color: var(--color-neutral-40);
  font-size: 0.75rem;
  height: 1.5rem;
  margin-right: 0.5em;
  outline: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

._button--table_o4fw5_141:hover {
  border: 1px solid var(--color-neutral-40);
  --text-color: var(--color-neutral-40);
}

._outlineButton_o4fw5_162 {
  background: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
  color: var(--color--neutral-10);
  line-height: 1;
  padding: 0.5rem 0 0.5rem 0.75rem;
  text-align: left;
}

._buttonMenu_o4fw5_172 {
  position: relative;
}

._buttonMenu_o4fw5_172 > button {
  padding: 0.25rem;
}

._flyout_o4fw5_180 {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--color--neutral-20);
  min-width: max-content;
}

._flyout--right_o4fw5_191 {
  right: 0;
}

._flyout_o4fw5_180 ._fang_o4fw5_195 {
  position: absolute;
  top: -0.5rem;
}

._flyout_o4fw5_180 ._fang--right_o4fw5_200 {
  right: 0rem;
}

._menuItem_o4fw5_204 {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid var(--color--neutral-20);
  display: flex;
  align-items: center;
  transition: all 0.2s var(--transition);
  color: var(--color-neutral-10);
}

._menuItem_o4fw5_204:hover {
  background: var(--color-legacy-gray-1);
}

._menuItem_o4fw5_204 > a,
._menuItem_o4fw5_204 > button {
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  background: none;
  padding: 0;
  color: var(--color-neutral-10);
}

._menuItem_o4fw5_204:last-child {
  border-bottom: none;
}

._positionedWrapper_o4fw5_232 {
  position: absolute;
  right: 0.5rem;
}
._positioned_o4fw5_232 {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: fill 0.25s var(--transition);
}

._positioned_o4fw5_232 path {
  --fill-color: var(--color--neutral-10);
  fill: var(--fill-color);
}

._positioned_o4fw5_232:hover path {
  --fill-color: var(--color-legacy-gray-6);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"button":"_button_o4fw5_1","medium":"_medium_o4fw5_23","small-medium":"_small-medium_o4fw5_30","smallMedium":"_small-medium_o4fw5_30","small":"_small_o4fw5_30","extra-small":"_extra-small_o4fw5_44","extraSmall":"_extra-small_o4fw5_44","cancel":"_cancel_o4fw5_52","button--cancel":"_button--cancel_o4fw5_53","buttonCancel":"_button--cancel_o4fw5_53","action":"_action_o4fw5_59","button--action":"_button--action_o4fw5_60","buttonAction":"_button--action_o4fw5_60","delete":"_delete_o4fw5_66","button--delete":"_button--delete_o4fw5_67","buttonDelete":"_button--delete_o4fw5_67","outline":"_outline_o4fw5_73","gray":"_gray_o4fw5_79","button--outline-icon":"_button--outline-icon_o4fw5_93","buttonOutlineIcon":"_button--outline-icon_o4fw5_93","rounded":"_rounded_o4fw5_109","borderless":"_borderless_o4fw5_113","actionButtons":"_actionButtons_o4fw5_120","buttonExport":"_buttonExport_o4fw5_130","red":"_red_o4fw5_135","button--table":"_button--table_o4fw5_141","buttonTable":"_button--table_o4fw5_141","outlineButton":"_outlineButton_o4fw5_162","buttonMenu":"_buttonMenu_o4fw5_172","flyout":"_flyout_o4fw5_180","flyout--right":"_flyout--right_o4fw5_191","flyoutRight":"_flyout--right_o4fw5_191","fang":"_fang_o4fw5_195","fang--right":"_fang--right_o4fw5_200","fangRight":"_fang--right_o4fw5_200","menuItem":"_menuItem_o4fw5_204","positionedWrapper":"_positionedWrapper_o4fw5_232","positioned":"_positioned_o4fw5_232"};
export { css, digest };
  