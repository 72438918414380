import { FC } from "react";

import { styled } from "@plan/core";
import { IconNewMessage, IconPauseCircle, IconPlayCircle } from "@plan/icons";
import { Box, Flex } from "@plan/layout";

import { useTimerContext } from "../contexts/TimerContext";

import { TimerButton } from "./Modal/styles/TimerButton";
import { TimerButtonCircle } from "./Modal/styles/TimerButtonCircle";
import { TimerButtonCircleGraphic } from "./Modal/styles/TimerButtonCircleGraphic";
import { TimerButtonContent } from "./Modal/styles/TimerButtonContent";

const TimerDropdown = styled("button", {
  display: "flex",
  alignItems: "center",
  width: "100%",
  border: "none",
  padding: "0",
  backgroundColor: "transparent",
  color: "$white",
  cursor: "pointer",
  variants: {
    collapsed: {
      true: {
        opacity: "0",
        visibility: "hidden",
      },
    },
  },
});

const TimerDropdownText = styled("div", {
  position: "relative",
  marginRight: "$space$2",
});

const TimerDropdownTime = styled("div", {
  lineHeight: "1",
  textAlign: "left",
});

const TimeUnit = styled("span", {
  fontSize: "$fontSizes$sm",
  fontWeight: "$normal",
  lineHeight: "1",
  letterSpacing: "0.0375rem",
  textTransform: "uppercase",
});

const DropdownProject = styled("div", {
  lineHeight: "1",
  textAlign: "left",
  color: "$colors$-neutral10",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontWeight: "$normal",
  fontSize: "$fontSizes$xs",
  width: "5rem",
  "@bpXl": {
    width: "7.375rem",
    fontSize: "$fontSizes$sm",
  },
});

const DropdownToggle = styled("div", {
  marginLeft: "auto",
  marginTop: "$space$0_5",
  marginRight: "$space$0_5",
});

interface TimerSidebarNavItemProps {
  sidebarOpen: boolean;
  newNavigationEnabled?: boolean;
}

const TimerButtonOld: FC<TimerSidebarNavItemProps> = ({ sidebarOpen }) => {
  const {
    actions: { toggleTimerModal, toggleTimerRecording },
    state: { entry, isRecording },
  } = useTimerContext();

  const handleButtonClick = () =>
    entry ? toggleTimerRecording() : toggleTimerModal();

  return (
    <TimerButton
      collapsed={!sidebarOpen}
      type="button"
      onClick={handleButtonClick}
    >
      <TimerButtonCircle isActive={isRecording}>
        <TimerButtonCircleGraphic isActive={isRecording} />
      </TimerButtonCircle>
      <TimerButtonContent collapsed={!sidebarOpen} isActive={isRecording} />
    </TimerButton>
  );
};

const TimerButtonWrapper: FC<TimerSidebarNavItemProps> = ({
  sidebarOpen,
  newNavigationEnabled,
}) => {
  if (!newNavigationEnabled)
    return <TimerButtonOld sidebarOpen={sidebarOpen} />;

  return <PrimaryNavTimerButton />;
};

export const TimerSidebarNavItem: FC<TimerSidebarNavItemProps> = ({
  sidebarOpen,
  newNavigationEnabled,
}) => {
  const {
    actions: { toggleTimerModal },
    state: { entry, recordingDisplayTime },
  } = useTimerContext();

  return (
    <>
      <TimerButtonWrapper
        sidebarOpen={sidebarOpen}
        newNavigationEnabled={newNavigationEnabled}
      />
      {!newNavigationEnabled && (
        <TimerDropdown
          collapsed={!sidebarOpen}
          type="button"
          onClick={toggleTimerModal}
        >
          <TimerDropdownText>
            <TimerDropdownTime>
              {recordingDisplayTime} <TimeUnit>hrs</TimeUnit>
            </TimerDropdownTime>
            <DropdownProject>
              {entry?.project?.name || "Select a project..."}
            </DropdownProject>
          </TimerDropdownText>
          <DropdownToggle>
            <IconNewMessage label="Edit" color="neutral" />
          </DropdownToggle>
        </TimerDropdown>
      )}
    </>
  );
};

// Below: All the bespoke timer stuff for the new sidebar nav

const PrimaryNavTimerIcon = styled(Box, {
  padding: "2px",
  "& > svg": {
    background: "none",
    width: "24px",
    height: "24px",
  },
});

const useMinuteSecondTimerDisplayTime = () => {
  const {
    state: { entry, isRecording, recordingDisplayTime },
  } = useTimerContext();

  if (!isRecording || !entry || !recordingDisplayTime) return "00:00";

  const hourDecimalRecordingTime = parseFloat(recordingDisplayTime);

  const hours = Math.floor(hourDecimalRecordingTime);
  const minutes = Math.floor((hourDecimalRecordingTime - hours) * 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};

const PrimaryNavTimerButton = () => {
  const {
    actions: { toggleTimerModal },
    state: { isRecording },
  } = useTimerContext();
  const displayTime = useMinuteSecondTimerDisplayTime();

  const timerCaption = isRecording ? displayTime : "Timer";

  const handleButtonClick = () => {
    toggleTimerModal();
  };

  const TimerIcon = isRecording ? IconPauseCircle : IconPlayCircle;
  const timercolor = isRecording ? "-danger10" : "-success30";

  return (
    <Flex
      onClick={handleButtonClick}
      css={{
        flexDirection: "column",
        alignItems: "center",
        borderLeft: "4px solid $colors$neutral40",
      }}
    >
      <PrimaryNavTimerIcon>
        <TimerIcon label="recording" color={timercolor} />
      </PrimaryNavTimerIcon>
      <Box css={{ fontSize: "$sm" }}>{timerCaption}</Box>
    </Flex>
  );
};
