
const digest = 'ae2a97bc425bfd50a3ae4d80edb14e7f8c91b9e304c2e98cdde4ae8490dd4498';
const css = `._header_1gy7t_1 {
  display: flex;
  padding-top: 8px;
  padding-bottom: 4px;
}
._number_1gy7t_6 {
  background: var(--color-neutral-40);
  display: flex;
  align-items: center;
  border-radius: 0.1875rem;
  color: var(--color-white);
  font-size: 0.625rem;
  line-height: 1;
  margin-right: 0.5rem;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0.25rem;
}
._project_1gy7t_19 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1;
  text-transform: none;
  color: var(--color-neutral-40);
}
._phase_1gy7t_26 {
  margin-top: 0.25rem;
  font-size: 0.875rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"header":"_header_1gy7t_1","number":"_number_1gy7t_6","project":"_project_1gy7t_19","phase":"_phase_1gy7t_26"};
export { css, digest };
  