/**
 * ⚠️ THIS FILE WAS AUTOMATICALLY GENERATED VIA @PLAN/ICONS' BUILD STEP.
 * DO NOT EDIT MANUALLY
 */
import * as React from "react";

import "react";

import type { IconProps } from "../Icon";
import { Icon } from "../Icon";

import * as AccessibleIcon from "@radix-ui/react-accessible-icon";
interface SvgIconPlayCircleProps
  extends React.ComponentProps<typeof AccessibleIcon.Root>,
    IconProps {}
const SvgIconPlayCircle = ({ label, ...props }: SvgIconPlayCircleProps) => (
  <AccessibleIcon.Root label={label}>
    {React.createElement(
      Icon,
      { viewBox: "0 0 24 24", fill: "none", ...props },
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.477 6.477 2 12 2A10 10 0 1 1 2 12Zm8.17 4.28L16 12.64a.76.76 0 0 0 0-1.28l-5.85-3.64A.75.75 0 0 0 9 8.35v7.3a.75.75 0 0 0 1.17.63Z"
        fill="currentColor"
      />
    )}
  </AccessibleIcon.Root>
);
export default SvgIconPlayCircle;
