import { ComponentProps, ReactNode } from "react";

import { styled } from "@plan/core";
import { Text } from "@plan/components";

import { format as formatDate, isValid, parse } from "date-fns";

// date-fns formatters
export const formatters = {
  // 2021-12-1 => Dec 1, 2021
  fullDateCardinal: "MMM d, yyyy",
  // 2021-12-1 => Dec 1, 2021
  fullDateCardinalWithOrdinal: "MMM do, yyyy",
  // 2022-03-31T18:02:40Z => March 31, 2022 at 12:00am
  fullDateCardinalWithTime: "MMM d, yyyy 'at' h:mma",
  // 2021-12-1 => 2021/12/01
  calendarDate: "yyyy/MM/dd",
  // 2021-12-1 => 2021
  year: "yyyy",
  // 2021-12-1 => 2021 Q4
  quarterInYear: "yyyy QQQ",
  // 2021-12-1 => December 2021
  monthInYear: "MMMM yyyy",
  // 2021-12-1 => Dec 01
  dayInMonth: "MMM dd",
  // December
  month: "MMMM",
  // 2021-12-1 => Dec
  monthAbbr: "MMM",
  // 2021-12-1 => Wed, Dec 1
  weekdayInMonth: "eee, MMM d",
  // 2022-03-31T18:02:40Z => Mar 31, 12:00am
  dayAndTime: "MMM d, h:mma",
};

export type DateDisplayFormatters = keyof typeof formatters;

export const formatDateDisplay = (
  date: Date,
  format: keyof typeof formatters
) => {
  return formatDate(date, formatters[format]);
};

export const asDate = (dateToConvert: Date | string | number): Date => {
  if (dateToConvert instanceof Date) return dateToConvert;

  if (typeof dateToConvert === "string") {
    const date = dateToConvert.includes("T")
      ? // If we're passed a date string with a time value,
        // try to format using a regular js Date
        new Date(dateToConvert)
      : // Otherwise Rely on ISO date (no time) format to parse because it is the
        // format our data uses most often
        parse(dateToConvert, "yyyy-MM-dd", new Date());

    // Handle ISO datetime-strings with or without timezone
    // `parseISO` from `date-fns` can cause the date to
    // be off by one if no timezone is passed
    return isValid(date)
      ? date
      : parse(dateToConvert.split("T")[0], "yyyy-MM-dd", new Date());
  }

  if (typeof dateToConvert === "number") return new Date(dateToConvert);

  // We should never hit this if we obey typescript
  return new Date(dateToConvert);
};

export const StyledDateDisplay = styled("span", {});

export type DateDisplayProps = ComponentProps<typeof StyledDateDisplay> & {
  value?: string | Date | number | undefined | null;
  format?: keyof typeof formatters;
  as?: ReactNode;
};

export const DateDisplay = ({
  value,
  format = "fullDateCardinal",
  ...props
}: DateDisplayProps) => {
  if (!value) return <Text css={{ color: "$colors$-neutral10" }}>--</Text>;

  const date = asDate(value);

  return (
    <StyledDateDisplay {...props}>
      {formatDateDisplay(date, format)}
    </StyledDateDisplay>
  );
};
