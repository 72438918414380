/* eslint-disable no-console */
function tracker({ event, ...data }) {
  if (process.env.NODE_ENV === "development") {
    console.log(`[Tracker]: ${event}`, data);
  }

  window.analytics?.track(event, data);
}

const reportsEvents = {
  trackProfitDriverReportExported: () =>
    tracker({
      event: "Profit driver report exported",
      feature: "Reports",
    }),
  trackOrgProfitReportExported: () =>
    tracker({
      event: "Org profit report exported",
      feature: "Reports",
    }),
};

const navigationEvents = {
  trackWhatsNewClicked: ({ hadNotification }) => {
    tracker({
      event: "What's New Clicked",
      feature: "Navigation",
      hadNotification,
    });
  },
  trackDemoOrganizationClicked: () =>
    tracker({
      event: "Demo organization clicked",
      feature: "Navigation",
    }),
  trackPrimaryOrganizationClicked: () => {
    tracker({
      event: "Primary organization clicked",
      feature: "Navigation",
    });
  },
  trackSearchResultClicked: ({ type, id }) => {
    tracker({
      event: "Universal Search Result Clicked",
      feature: "Navigation",
      type,
      id,
    });
  },
  trackSearchOpened: ({ withKeyboardShortcut }) => {
    tracker({
      event: "Universal Search Bar Opened",
      feature: "Navigation",
      withKeyboardShortcut: !!withKeyboardShortcut,
    });
  },
  trackHelpLinkClicked: () =>
    tracker({
      event: "Help link clicked",
      feature: "Navigation",
    }),
  trackReportsLinkClicked: () =>
    tracker({
      event: "Reports link clicked",
      feature: "Navigation",
    }),
};

const invoicesEvents = {
  trackInvoiceEditFooterClicked: ({ footerSet, projectId, invoiceNumber }) =>
    tracker({
      event: "Edit Footer Clicked",
      feature: "Invoices",
      footerSet,
      projectId,
      invoiceNumber,
    }),
  trackInvoiceFooterUpdated: ({ footerSet, projectId, invoiceNumber }) =>
    tracker({
      event: "Invoice Footer Updated",
      feature: "Invoices",
      footerSet,
      projectId,
      invoiceNumber,
    }),
  trackInternalNoteEdited: ({ projectId, invoiceNumber }) =>
    tracker({
      event: "Internal Note Edited",
      feature: "Invoices",
      projectId,
      invoiceNumber,
    }),
  trackDownloadPdfClick: ({ invoiceId }) =>
    tracker({
      event: "Download PDF - Clicked",
      feature: "Invoices",
      invoice: invoiceId,
    }),
};

const settingsEvents = {
  trackSettingsCompanyAddressEdited: () =>
    tracker({
      event: "Company address edited",
      feature: "Settings",
    }),
  trackSettingsCompanyLogoEdited: () =>
    tracker({
      event: "Company logo edited",
      feature: "Settings",
    }),
  trackSettingsCompanyNameEdited: ({ name }) =>
    tracker({
      event: "Company name edited",
      feature: "Settings",
      name,
    }),
  trackSettingsLocaleEdited: ({ value }) =>
    tracker({
      event: "Company locale edited",
      feature: "Settings",
      value,
    }),
  trackSettingsTimeZoneEdited: ({ value }) =>
    tracker({
      event: "Company time zone edited",
      feature: "Settings",
      value,
    }),
  trackSettingsCurrencyEdited: ({ value }) =>
    tracker({
      event: "Company currency edited",
      feature: "Settings",
      value,
    }),
  trackSettingsOverheadMultiplierEdited: ({ value }) =>
    tracker({
      event: "Overhead multiplier edited",
      feature: "Settings",
      value,
    }),
  trackSettingsTimesheetLockDaysTimeEdited: () =>
    tracker({
      event: "Timesheet lock days & time edited",
      feature: "Settings",
    }),
  trackSettingsTimerOn: () =>
    tracker({
      event: "App timer on",
      feature: "Settings",
    }),
  trackSettingsTimerOff: () =>
    tracker({
      event: "App timer off",
      feature: "Settings",
    }),
  trackSettingsCategoryCreate: () =>
    tracker({
      event: "Category created",
      feature: "Settings",
    }),
  trackSettingsCategoryUpdate: () =>
    tracker({
      event: "Category update",
      feature: "Settings",
    }),
  trackSettingsCategoryDelete: () =>
    tracker({
      event: "Category deleted",
      feature: "Settings",
    }),
  trackSettingsPhaseCreate: () =>
    tracker({
      event: "Phase template created",
      feature: "Settings",
    }),
  trackSettingsPhaseDelete: () =>
    tracker({
      event: "Phase template deleted",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateCreate: ({ feature }) =>
    tracker({
      event: "Role template created",
      feature,
    }),
  trackSettingsRoleTemplateArchive: () =>
    tracker({
      event: "Role template archived",
      feature: "Settings",
    }),
  trackSettingsRoleTemplateUpdate: () =>
    tracker({
      event: "Role template updated",
      feature: "Settings",
    }),
  trackSettingsTimesheetsReminderOn: () =>
    tracker({
      event: "Timesheets reminder on",
      feature: "Settings",
    }),
  trackSettingsTimesheetsReminderOff: () =>
    tracker({
      event: "Timesheets reminder off",
      feature: "Settings",
    }),
  trackSettingsTimesheetsLocked: () =>
    tracker({
      event: "Timesheets locked",
      feature: "Settings",
    }),
  trackSettingsTimesheetsUnlocked: () =>
    tracker({
      event: "Timesheets unlocked",
      feature: "Settings",
    }),
  trackSettingsTimesheetsOverheadCreate: ({ name }) =>
    tracker({
      event: "Timesheet overhead created",
      feature: "Settings",
      name,
    }),
  trackSettingsTimesheetsOverheadDelete: () =>
    tracker({
      event: "Timesheet overhead deleted",
      feature: "Settings",
    }),
  trackSettingsTimesheetsAccountingIdAdd: () =>
    tracker({
      event: "Timesheet accounting ID added",
      feature: "Settings",
    }),
  trackSettingsTimesheetsActivityCreate: ({ value }) =>
    tracker({
      event: "Timesheet activity created",
      feature: "Settings",
      value,
    }),
  trackSettingsTimesheetsActivityUpdate: ({ value }) =>
    tracker({
      event: "Timesheet activity updated",
      feature: "Settings",
      value,
    }),
  trackSettingsTimesheetsActivityDelete: () =>
    tracker({
      event: "Timesheet activity deleted",
      feature: "Settings",
    }),
  trackSettingsInvoicesTermCreate: ({ days, name }) =>
    tracker({
      event: "Invoice term created",
      feature: "Settings",
      days,
      name,
    }),
  // Note: does not exist
  trackSettingsInvoicesTermDelete: () =>
    tracker({
      event: "Invoice term deleted",
      feature: "Settings",
    }),
  trackSettingsInvoicesExpenseCategoryCreate: ({ name }) =>
    tracker({
      event: "Invoice expense category created",
      feature: "Settings",
      name,
    }),
  trackSettingsInvoicesExpenseCategoryMappedToQB: ({ name }) =>
    tracker({
      event: "Invoice expense category mapped to quickbooks",
      feature: "Settings",
      name,
    }),
  trackSettingsInvoicesExpenseCategoryDelete: () =>
    tracker({
      event: "Invoice expense category deleted",
      feature: "Settings",
    }),
  trackSettingsInvoiceFooterUpdated: ({ footerSet }) =>
    tracker({
      event: "Invoice Footer Updated",
      feature: "Settings",
      footerSet,
    }),
  trackSettingsPermissionsEdit: () =>
    tracker({
      event: "Permissions edited",
      feature: "Settings",
    }),
  trackSettingsPermissionsCreate: () =>
    tracker({
      event: "Permissions created",
      feature: "Settings",
    }),
  trackSettingsPermissionsDelete: () =>
    tracker({
      event: "Permissions deleted",
      feature: "Settings",
    }),
  trackSettingsPermissionsDuplicate: () =>
    tracker({
      event: "Permissions duplicated",
      feature: "Settings",
    }),
  trackSettingsConsultantTemplateCreate: () =>
    tracker({
      event: "Consultant Template created",
      feature: "Settings",
    }),
  trackSettingsConsultantTemplateUpdate: () =>
    tracker({
      event: "Consultant Template updated",
      feature: "Settings",
    }),
  trackSettingsConsultantTemplateDelete: () =>
    tracker({
      event: "Consultant Template deleted",
      feature: "Settings",
    }),
  trackSettingsDailyNotificationToggled: ({ checked }) =>
    tracker({
      event: "Daily Notification Toggled",
      feature: "Settings",
      checked,
    }),
  trackSettingsTimeOffEnabled: () =>
    tracker({
      event: "Time Off Enabled",
      feature: "Settings",
    }),
  trackSettingsTimeOffDisabled: () =>
    tracker({
      event: "Time Off Disabled",
      feature: "Settings",
    }),
  trackImageUploadFailed: ({ fileType, fileDimensions, organizationId }) =>
    tracker({
      event: "Image upload failed",
      feature: "Settings",
      fileType,
      fileDimensions,
      organizationId,
    }),
};

const subscriptionEvents = {
  trackBillingDetailsButtonClicked: () =>
    tracker({
      event: "Billing Details button clicked",
      feature: "Subscriptions",
    }),
  trackRenewSubscriptionButtonClicked: () =>
    tracker({
      event: "Renew Subscription button clicked",
      feature: "Subscriptions",
    }),
  trackChangeBillingCycleButtonClicked: () =>
    tracker({
      event: "Change Billing Cycle button clicked",
      feature: "Subscriptions",
    }),
  trackPlanModalDisplay: ({ upgradeModalTitle, pathString }) =>
    tracker({
      event: "Plan Modal - Displayed",
      modal_title: upgradeModalTitle,
      path: pathString,
    }),
  trackPlanModalClick: ({
    upgradeModalTitle,
    pathString,
    columnTitle,
    buttonText,
    planIntervalSetting,
  }) =>
    tracker({
      event: "Plan Modal CTA - Clicked",
      modal_title: upgradeModalTitle,
      path: pathString,
      column_title: columnTitle,
      button_text: buttonText,
      plan_interval_setting: planIntervalSetting,
    }),
  trackModalGrowUpgradeClick: ({ pathString }) =>
    tracker({
      event: "Upgrade to Grow Button - Clicked",
      path: pathString,
    }),
  trackPlanPageCtaClicked: ({ columnTitle, buttonText, planIntervalSetting }) =>
    tracker({
      event: "Plan Page - Plan CTA - Clicked",
      column_title: columnTitle,
      button_text: buttonText,
      plan_interval_setting: planIntervalSetting,
    }),
  trackBillingPageCtaClicked: ({
    columnTitle,
    buttonText,
    planIntervalSetting,
  }) =>
    tracker({
      event: "Billing Page - Plan CTA - Clicked",
      column_title: columnTitle,
      button_text: buttonText,
      plan_interval_setting: planIntervalSetting,
    }),
  trackUpgradeLander: ({ pathString }) =>
    tracker({
      event: "Upgrade Lander - Visited",
      path: pathString,
    }),
  trackUpgradeLanderCtaClick: ({ pathString, buttonText }) =>
    tracker({
      event: "Upgrade Lander CTA - Clicked",
      path: pathString,
      button_text: buttonText,
    }),
};

const paymentEvents = {
  invoiceLinkCopied: ({ paymentRequested, paymentType, client, invoiceId }) =>
    tracker({
      event: "Invoice link copied",
      feature: "Payments",
      paymentRequested: paymentRequested,
      client: client,
      invoice_id: invoiceId,
      payment_type: paymentType,
      // Amplitude group for invoices
      integrations: {
        amplitude: {
          groups: {
            invoice: invoiceId,
          },
        },
      },
    }),
};

const directoryEvents = {
  trackAddMemberButtonClick: () => {
    tracker({
      event: "Add member button clicked",
      feature: "Team",
    });
  },
  trackAddConsultantModalOpen: () => {
    tracker({
      event: "Add Consultant Modal Displayed ",
      feature: "Team",
    });
  },
};

const projectsEvents = {
  trackTaskComplete: () =>
    tracker({
      event: "Task Completed",
      feature: "Projects",
    }),
  trackPhaseBudgetEdited: () =>
    tracker({
      event: "Phase Budget Edited",
      feature: "Projects",
    }),
  trackPhasePercentEdited: () =>
    tracker({
      event: "Phase Percent Edited",
      feature: "Projects",
    }),
  trackRoleHoursEdited: () =>
    tracker({
      event: "Role Hours Edited",
      feature: "Projects",
    }),
  trackRolePercentEdited: () =>
    tracker({
      event: "Role Percent Edited",
      feature: "Projects",
    }),
};

const onboardingEvents = {
  trackOnboardingActionClicked: ({ action }) =>
    tracker({
      event: "Onboarding Action Clicked",
      feature: "Onboarding",
      action,
    }),
  trackOnboardingChecklistButtonClicked: () =>
    tracker({
      event: "Onboarding Checklist Button Clicked",
      feature: "Onboarding",
    }),
};

const signupEvents = {
  trackSignupGetStartedButtonClicked: () =>
    tracker({
      event: "Signup Flow - Get Started Form Button - Clicked",
      feature: "Signup",
    }),
  trackSignupICPQualification: ({ ...formValues }) =>
    tracker({
      event: "Signup Flow - ICP Determination - Succeeded",
      feature: "Signup",
      ...formValues,
    }),
  trackSignupCreatePasswordFormClicked: () =>
    tracker({
      event: "Signup Flow - Create Password Form Button - Clicked",
      feature: "Signup",
    }),
  trackSignupTellUsMoreButtonClicked: () =>
    tracker({
      event: "Signup Flow - Tell Us More Form Button - Clicked",
      feature: "Signup",
    }),
  trackSignupFormFieldChange: ({ input_name, input_value, url }) =>
    tracker({
      event: "Signup Flow - Save Field Onblur - Succeeded",
      feature: "Signup",
      input_name,
      input_value,
      url,
    }),
};

const payrollEvents = {
  downloadedPaystub: ({ paystub, paystubProfileId }) =>
    tracker({
      event: "Paystub Downloaded",
      feature: "Payroll",
      paystub,
      paystubProfileId,
    }),
  downloadedPayrollReports: ({ reportType, startDate, endDate }) =>
    tracker({
      event: "Payroll Reports Downloaded",
      feature: "Payroll",
      reportType,
      startDate,
      endDate,
    }),
  trackGetStartedTodayClicked: () =>
    tracker({
      event: "Get Started Today Clicked",
      feature: "Payroll",
    }),
};

const timerEvents = {
  trackTimerStarted: () =>
    tracker({
      event: "Timer started",
      feature: "Timer",
    }),
  trackTimerStopped: () =>
    tracker({
      event: "Timer stopped",
      feature: "Timer",
    }),
};

const quickbooksEvents = {
  trackClientImportStarted: ({ userId, organizationId }) =>
    tracker({
      event: "Quickbooks - Client import started",
      feature: "Quickbooks",
      integrations: {
        amplitude: {
          groups: {
            user: userId,
            organization: organizationId,
          },
        },
      },
    }),
};

const staffingEvents = {
  trackCopyFromLastWeekButtonClicked: ({ week }) =>
    tracker({
      event: "Copy From Last Week Button Clicked",
      feature: "Staffing",
      week,
    }),
  trackRebalanceHoursApplyButtonClicked: ({ phaseId, roleId }) =>
    tracker({
      event: "Rebalance Hours Apply Button Clicked",
      feature: "Staffing",
      phaseId,
      roleId,
    }),
  trackStaffingHoursEdited: ({ organizationId }) =>
    tracker({
      event: "Staffing allocation edited",
      feature: "Staffing",
      organizationId,
    }),
  trackStaffingPhaseAssigned: ({ organizationId, phaseId }) =>
    tracker({
      event: "User assigned a profile to a phase via Staffing",
      feature: "Staffing",
      organizationId,
      phaseId,
    }),
  trackStaffingOverheadAssigned: ({ organizationId, overheadId }) =>
    tracker({
      event: "user assigned a profile to a overhead via Staffing",
      feature: "Staffing",
      organizationId,
      overheadId,
    }),
  trackProjectStaffingProfileEdit: ({ organizationId, phaseId }) =>
    tracker({
      event: "Profile edited via staffing projeccts",
      feature: "Staffing",
      organizationId,
      phaseId,
    }),
  trackProjectStaffingTimelineEdit: ({ organizationId, phaseId }) =>
    tracker({
      event: "Phase timeline updated via Staffing projects",
      feature: "Staffing",
      organizationId,
      phaseId,
    }),
};

const eventTypes = {
  ...reportsEvents,
  ...navigationEvents,
  ...settingsEvents,
  ...subscriptionEvents,
  ...paymentEvents,
  ...directoryEvents,
  ...projectsEvents,
  ...invoicesEvents,
  ...onboardingEvents,
  ...signupEvents,
  ...payrollEvents,
  ...timerEvents,
  ...quickbooksEvents,
  ...staffingEvents,
};

export const eventTracker = (event, data) => {
  try {
    eventTypes[event](data);
  } catch (err) {
    console.warn("Tracking error", err);
  }
};
